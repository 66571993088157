<template>
  <b-card>
    <subscribe
      @subscription-complete="$router.replace({ name: 'home' })"
    />
  </b-card>
</template>

<script>
import Subscribe from '@/components/Subscribe.vue'
import {
  BCard,
} from 'bootstrap-vue'

export default {
  name: 'SubscribeView',
  components: {
    Subscribe,
    BCard,
  },
}
</script>

<style scoped>

</style>
